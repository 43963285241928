.skeleton-list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .skeleton {
        padding: 24px;
        background-color: #fff;
        border-radius: 15px;
        min-height: 126px;

        display: flex;
        gap: 3px;
        flex-direction: column;

        .skeleton-content {
            border-radius: 3px;

            animation-duration: 1.25s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #f6f6f6;
            background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
            background-size: 800px 104px;
        }

        .skeleton-title {
            height: 24px;
            margin-bottom: 8px;
            width: 40%;
        }

        .skeleton-text1,
        .skeleton-text2 {
            height: 18px;
        }

        .skeleton-text1 {
            width: 80%;
        }

        .skeleton-text2 {
            width: 60%;
        }
    }
}
