.btn-red {
    background-color: #ce3249;
    border-color: #ce3249;
    color: #fff;

    &:hover {
        background-color: darken(#ce3249, 10%);
        border-color: darken(#ce3249, 10%);
        color: #fff;
    }

    &:focus {
        background-color: darken(#ce3249, 10%);
        border-color: darken(#ce3249, 10%);
        color: #fff;
    }

    &:active {
        background-color: darken(#ce3249, 10%);
        border-color: darken(#ce3249, 10%);
        color: #fff;
    }
}

.btn-light {
    background-color: #d8d8d8;
    color: #000;

    &:hover {
        background-color: darken(#d8d8d8, 10%);
        color: #000;
    }

    &:focus {
        background-color: darken(#d8d8d8, 10%);
        color: #000;
    }

    &:active {
        background-color: darken(#d8d8d8, 10%);
        color: #000;
    }
}

.btn-white {
    background-color: #fff;
    color: #000;

    &:hover {
        background-color: darken(#fff, 10%);
        color: #000;
    }

    &:focus {
        background-color: darken(#fff, 10%);
        color: #000;
    }

    &:active {
        background-color: darken(#fff, 10%);
        color: #000;
    }
}

.btn {
    font-weight: 600;
    position: relative;
    border-radius: var(--btn-radius, 15px);
    // border-width: 0 !important;
}
.btn-lg {
    padding-left: 25px;
    padding-right: 25px;
    border-radius: var(--btn-lg-radius, 25px);
}
.btn-block {
    width: 100%;
    display: block;
}

.btn-action {
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
}

.btn-error {
    animation: shake 0.5s;
}
@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}
.btn-submit {
    position: relative;

    .la-ball-pulse {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes ball-pulse {
    0%,
    100%,
    60% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    30% {
        -webkit-transform: scale(0.01);
        transform: scale(0.01);
        opacity: 0.1;
    }
}
.la-ball-pulse > div {
    background-color: #222;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    -webkit-animation: ball-pulse 1s ease infinite;
    animation: ball-pulse 1s ease infinite;
}
.la-ball-pulse > div:nth-child(1) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.la-ball-pulse > div:nth-child(2) {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}
.la-ball-pulse > div:nth-child(3) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.la-ball-pulse-sm > div {
    width: 6px;
    height: 6px;
}
.la-ball-pulse-2x div {
    width: 16px;
    height: 16px;
    margin: 0 4px;
}
.la-ball-pulse-white div {
    background-color: #fff;
}

.la-ball-pulse-2x-white div {
    background-color: #fff;
}
