.form-wrap {
    position: relative;

    .form-label {
        pointer-events: none;
        position: absolute;

        color: #5f6368;
        font-size: $font-size-base;
        font-weight: 400;
        left: 20px;

        transition:
            transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
            opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
            font-size 150ms cubic-bezier(0.4, 0, 0.2, 1);
        white-space: nowrap;
        width: auto;
        z-index: 1;

        max-width: calc(100% - (2 * 8px));
        overflow: hidden;
        padding: 0 4px;

        background-color: #fff;
        border-radius: 3px;
        opacity: 0.8;

        top: 9px;

        line-height: 2;
    }

    .form-control-lg ~ .form-label,
    .form-select-lg ~ .form-label {
        top: 10px;

        font-size: $font-size-placeholder;
        font-weight: 500;
    }

    .form-control,
    .form-select {
        background-color: #fff;

        &:focus {
            box-shadow: 0 0 0 1px var(--input-focus-color);
            border-color: var(--input-focus-color);
        }
    }

    .form-control-lg {
        padding-top: 25px;
        height: calc(1.5em + 1rem + 2px);
    }

    .form-select:focus ~ .form-label,
    .form-select:valid ~ .form-label,
    .form-control:focus ~ .form-label,
    .form-control:not(:placeholder-shown).form-control:not(:focus) ~ .form-label {
        font-size: 10px;
        transform: translateY(-15px);
        opacity: 1;
    }

    .is-invalid {
        border-color: $red;
        box-shadow: 0 0 0 1px $red;
    }

    .form-control:focus + .form-label {
        color: $body-color;
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
@media (max-width: 992px) {
    .form-container-mb {
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
    }
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: flex;
    gap: 5px;
    font-weight: bold;
    font-size: 90%;
    align-items: center;
}
