.swal2-popup {
    border-radius: $border-radius !important;

    .swal2-confirm,
    .swal2-cancel {
        font-family: $font-family-sans-serif;
        line-height: 1.3;
        font-weight: $btn-font-weight;
        min-width: 120px;
        border-radius: $border-radius;
        font-weight: bold;
    }

    .swal2-confirm {
        background-color: $primary;
        color: $primary-color;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
        }

        &:hover {
            color: $primary-color;
            background-color: darken($primary, 10%);
            border-color: darken($primary, 10%);
        }
    }

    .swal2-cancel {
        color: #212529;
        background-color: #ebebeb;
        border-color: #ebebeb;
    }
}

.swal2-popup.swal2-toast {
    background-color: #0088ff !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .swal2-html-container {
        margin: 0 !important;
        padding: 0 15px !important;
        text-align: center !important;
        color: #fff;
    }
}

body.swal2-toast-shown .swal2-container {
    padding: 0;
}

body.swal2-toast-shown .swal2-container.swal2-top-end,
body.swal2-toast-shown .swal2-container.swal2-top-right {
    left: 0;
    right: 0;
    width: auto;
}

@media (min-width: 761px) {
    .swal2-popup.swal2-toast {
        border-radius: 4px !important;
    }
    body.swal2-toast-shown .swal2-container {
        padding: 10px;
    }

    body.swal2-toast-shown .swal2-container.swal2-top-end,
    body.swal2-toast-shown .swal2-container.swal2-top-right {
        left: auto;
        right: 0;
        width: auto;
    }
}
