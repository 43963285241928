.post-item {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    .post-thumbnail {
        position: relative;
        display: block;
        overflow: hidden;
        height: 0;
        padding-top: 56.25%;
        background: hsla(0, 0%, 93.3%, 0.9333333333);
        border-radius: 15px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .thumbnail-overlay {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            left: 10px;
            display: flex;
            align-items: center;

            gap: 5px;
        }

        .badge-overlay {
            width: 30px;
            height: 30px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: saturate(180%) blur(20px);
            border-radius: 8px;
            color: #fff;
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

            span {
                font-size: 14px;
            }
        }
    }

    .post-content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
        font-size: 14px;
    }

    .post-title {
        font-weight: 700;
        font-size: 14px;
        color: #000;
    }

    .post-desc {
        font-size: 13px;
        color: #999;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: normal;
        line-height: 16px;
        max-height: 32px;
    }

    .post-date {
        font-size: 80%;
        color: #999;
    }
}
