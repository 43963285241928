canvas,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
menu,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
}

html,
body {
    height: 100%;
}
body {
    overflow-y: scroll;
    letter-spacing: 0.012em;
}

h1,
h2,
h3 {
    font-weight: bold;
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.17em;
}

a {
    text-decoration: none;
    color: inherit;
}

.header-skeleton {
    background-color: #fff;
    min-height: var(--header-height, 155.6px);
}

.min-h-screen {
    min-height: 100vh;
}

.container {
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

.relative {
    position: relative;
}

svg {
    fill: currentColor;
}

.image-outer {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MjgiIGhlaWdodD0iMzMyLjg4OSI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJiIiB4MT0iLjk5NCIgeTE9Ii41MDYiIHgyPSIuMDIzIiB5Mj0iLjUwNiIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2FiYjZkMiIgc3RvcC1vcGFjaXR5PSIuMTc2Ii8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjYWJiNmQyIiBzdG9wLW9wYWNpdHk9Ii4wOTgiLz48L2xpbmVhckdyYWRpZW50PjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZD0iTTY4IDBoLTQyOHYzMzIuODg5aDI5My42NjdhNzYuMDg5IDc2LjA4OSAwIDAwNTMuNjkzLTIyLjE3Nkw2OCAyMzAuNHoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM2MCkiIGZpbGw9IiNlNmVhZjgiLz48L2NsaXBQYXRoPjwvZGVmcz48cGF0aCBkPSJNNDI4IDBIMHYzMzIuODg5aDI5My42NjdhNzYuMDg5IDc2LjA4OSAwIDAwNTMuNjkzLTIyLjE3Nkw0MjggMjMwLjR6IiBmaWxsPSIjZTZlYWY4Ii8+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBkPSJNMCAyNTYuMTY1bDY0NS44MjgtLjlMNjQ2LjE4MiAwIC4zNTQuOXoiIHRyYW5zZm9ybT0icm90YXRlKC00NC4wMyA0NzEuOTMzIDExNC43OTkpIiBmaWxsPSJ1cmwoI2IpIi8+PC9nPjwvc3ZnPg==);
    background-size: 750px auto;
    background-repeat: no-repeat;
    background-position: center -350px;
    background-attachment: fixed;
}

.row {
    margin-left: -5px;
    margin-right: -5px;

    > * {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.questions {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-left: -15px;
    margin-right: -15px;

    padding: 15px;
    background-color: #f2f2f2;

    .question-index {
        color: #6c757d;
        font-size: 80%;
        font-weight: 500;
    }

    .question-topic {
        font-weight: bold;
    }
}
