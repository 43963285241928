// Body
$body-bg: #f9f8ff;
$body-color: #000066;

// Typography
$font-family-sans-serif: "Petchlamoon", sans-serif;
$font-size-base: 1rem;
$font-size-placeholder: 1rem;
$font-size-custom-select: 14px;
$line-height-base: 1.5;
$font-weight: 400;

$container-max-widths: (
    sm: 640px
);

$primary: #ffa977;
$primary-color: #000066;
$btn-radius: 10px;
$btn-lg-radius: 25px;
$border-radius: 10px;

$gutter: 5px;

:root {
    --font-size: 18px;
    --font-family: -apple-system, "Petchlamoon", Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol;
    --nav-font-size: 18px;

    --primary-bg: #ffa977;
    --primary-color: #fff;

    --secondary-color: #712d7d;
    --content-font-family: -apple-system, Sarabun, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol;

    --card-post-title-size: 18px;
    --card-post-title-line-height: 1.2;

    --footer-bg: #008060;
    --footer-color: #fff;
    --footer-link-color: #fff;
    --footer-link-hover-color: #fff;

    --article-max-width: 880px;
    --violet: #814369;

    --border-radius: 10px;
    --border-radius-lg: 15px;

    --btn-radius: 10px;
    --btn-lg-radius: 15px;

    --primary-rgb: 0 128 96;

    --input-focus-color: #000066;
}
