.page-content {
    padding: 15px;

    .card-oversize {
        margin-left: -15px;
        margin-right: -15px;
        border-radius: 25px;
    }
}
.card-column {
    display: flex;
}
.card,
.card-column {
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
}
.card {
    border-width: 0;
    background-color: #fff;
    padding: 24px;
}
.page-alert {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .error {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        width: 100%;
    }

    .empty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        padding: 50px;
    }

    .page-alert-icon {
        font-size: 5em;
    }
}
.has-BottomNavigation {
    padding-bottom: 115px;
}

.skeleton-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.skeleton {
    padding: 24px;
    background-color: #fff;
    border-radius: 15px;
    min-height: 126px;

    display: flex;
    gap: 4px;
    flex-direction: column;

    .skeleton-content {
        border-radius: 15px;

        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f6f6;
        background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
        background-size: 800px 104px;
    }

    .skeleton-title {
        height: 20px;
        margin-bottom: 8px;
        width: 40%;
    }

    .skeleton-text1,
    .skeleton-text2 {
        height: 13px;
    }

    .skeleton-text1 {
        width: 80%;
    }

    .skeleton-text2 {
        width: 60%;
    }
}
